import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicLink from "components/dynamic_link";

import Api from "api/requests";
import Actions from "redux/actions";
import * as popups from "constants/popup-types";
import { getFullMediaUrl, convertToSortedArray } from "app/functions";

import downArrow from "assets/part-one/down-arrow.svg";
import "./index.scss";
import { googleAnalyticsManager } from "app/GoogleAnalytics/GoogleAnalyticsManager";

const PartOne = () => {
  const dispatch = useDispatch();

  let bgimagesArr = useSelector((store) => store.gd.home_top_page_imagesArr);
  bgimagesArr = convertToSortedArray(bgimagesArr);

  const mobilePhoto = getFullMediaUrl(bgimagesArr[0]?.imageArr?.mobile_path);
  const desktopPhoto = getFullMediaUrl(bgimagesArr[0]?.imageArr?.path);
  const title = bgimagesArr[0].title;
  const subtitle = bgimagesArr[0].subtitle;

  const text = useSelector((store) => store.gd.translationsArr);

  const deviceState = useSelector((store) => store.deviceState);
  const quickActions = (
    <div
      className={`one-rectangle-title ${
        deviceState.isDesktop ? "one-rectangle-content-item" : ""
      }`}
    >
      {text["service_centers_fast_actions"]}
    </div>
  );

  const iconsArr = useSelector(
    (store) => store.gd.icons_groupsArr.fast_actions_iconsArr
  );
  const imagesArr = convertToSortedArray(iconsArr);

  function openLeadPopup(text, form_id) {
    reportToGooglaAnalytics(text);
    dispatch(
      Actions.addPopup({
        type: popups.Battery,
        payload: { sendForm: sendForm, form_id: form_id },
      })
    );
  }

  function openIframePopup(src, text) {
    reportToGooglaAnalytics(text);
    dispatch(Actions.addPopup({ type: popups.Iframe, payload: { src: src } }));
  }

  function sendForm(form, form_id) {
    Api.SendDynamicForm({
      payload: {
        name: form.fullname,
        car_detail: form.modelAndManufacter,
        phone: form.phone,
        car_number: form.licence,
        dynamic_form_id: form_id,
      },
    });
  }

  function reportToGooglaAnalytics(text) {
    googleAnalyticsManager.clickOnButton(text);
  }

  return (
    <>
      <div className="home-one">
        {!deviceState.isDesktop ? (
          <img className={"one-bg"} src={mobilePhoto} alt={""} />
        ) : (
          <img className={"one-bg"} src={desktopPhoto} alt={""} />
        )}

        <div className="text-wrapper">
          <div className="one-green-title">{title}</div>
          <div className={"one-orange-title"}>{subtitle}</div>
        </div>
      </div>
      <div className="one-rectangle">
        {!deviceState.isDesktop && quickActions}
        <div className={"one-rectangle-content"}>
          {deviceState.isDesktop && quickActions}
          {imagesArr.map((item, index) => {
            const form_id = parseInt(item.form_id);
            if (form_id) {
              return (
                <div
                  className={"one-rectangle-content-item"}
                  key={index}
                  onClick={() => {
                    openLeadPopup(item.title, form_id);
                  }}
                >
                  <img
                    src={getFullMediaUrl(item?.imageArr?.path)}
                    className={"action-img"}
                    alt={""}
                  />
                  <p className={"item-p"}>{item.title}</p>
                </div>
              );
            } else if (
              item.linkArr.type === "iframe" &&
              deviceState.isDesktop
            ) {
              return (
                <div
                  className={"one-rectangle-content-item"}
                  key={index}
                  onClick={() =>
                    openIframePopup(
                      item.linkArr.route + "?iframe-mode=true",
                      item.title
                    )
                  }
                >
                  <img
                    src={getFullMediaUrl(item?.imageArr?.path)}
                    className={"action-img"}
                    alt={""}
                  />
                  <p className={"item-p"}>{item.title}</p>
                </div>
              );
            } else {
              return (
                <DynamicLink
                  className={ "one-rectangle-content-item" + (item.linkArr.route === '' ? ' no-cursor' : '')}
                  data={item.linkArr}
                  key={index}
                  onClick={() => reportToGooglaAnalytics(item.title)}
                >
                  <img
                    src={getFullMediaUrl(item?.imageArr?.path)}
                    className={"action-img"}
                    alt={""}
                  />
                  <p className={"item-p"}>{item.title}</p>
                </DynamicLink>
              );
            }
          })}
        </div>
        <a href="#home-two">
          <img className={"one-down-arrow"} src={downArrow} alt={""} />
        </a>
      </div>
    </>
  );
};

export default PartOne;
