import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFullMediaUrl, convertToSortedArray } from "app/functions";

import Actions from "redux/actions";
import * as popups from "constants/popup-types";

import "./index.scss";
import ButtonBasic from "../../../../components/common/buttons/button_basic";
import { googleAnalyticsManager } from "app/GoogleAnalytics/GoogleAnalyticsManager";

const PartTwo = () => {
  const serviceCallIconsArr = useSelector(
    (store) => store.gd.icons_groupsArr.service_call_iconsArr
  );
  const imagesArr = convertToSortedArray(serviceCallIconsArr);

  const deviceState = useSelector((store) => store.deviceState);
  const text = useSelector((store) => store.gd.translationsArr);

  const genralContent = useSelector(
    (store) => store.gd.general_content_imagesArr
  );
  const part2Content = genralContent["part2_content"];
  const mobilePhoto = getFullMediaUrl(part2Content?.imageArr?.mobile_path);
  const desktopPhoto = getFullMediaUrl(part2Content?.imageArr?.path);
  const title = part2Content.title;
  const subtitle = part2Content.subtitle;
  const content = part2Content.content;
  const dispatch = useDispatch();

  const roadSholomoSosUrl = "https://" + basicHost + "/sos";

  function openAppLink(text = "", url) {
    googleAnalyticsManager.clickOnButton(text);
    window.open(url);
  }

  function openIframePopup(src, text = "") {
    googleAnalyticsManager.clickOnButton(text);

    dispatch(Actions.addPopup({ type: popups.Iframe, payload: { src: src } }));
  }

  const mobileContent = (
    <>
      <div className={"two-big-title"}>{title}</div>
      <div className={"two-medium-title"}>{subtitle}</div>
      <div className={"two-small-title"}>{content}</div>
      <div className={"two-attributes-wrapper"}>
        {imagesArr.map((item, index) => {
          return (
            <div className={"two-attribute"} key={index}>
              <img src={getFullMediaUrl(item?.imageArr?.path)} alt={""} />
              <div className={"two-attribute-text"}>{item.title}</div>
            </div>
          );
        })}
      </div>
      {deviceState.isDesktop && (
        <ButtonBasic
          className={"btn order-btn"}
          btnText={text["service_centers_get_service_call_btn"]}
          onClick={() => {
            openAppLink(text["service_centers_get_service_call_btn"], 'https://shlomotamidken.onelink.me/C16e/xejvc923');
          }}
        />
      )}
      {!deviceState.isDesktop && (
        <a href={roadSholomoSosUrl} target="_blank" noopener>
          <ButtonBasic
            className={"btn order-btn"}
            btnText={text["service_centers_get_service_call_btn"]}
            onClick={() => {
              openAppLink(text["service_centers_get_service_call_btn"], 'https://shlomotamidken.onelink.me/C16e/xejvc923');
            }}
          />
        </a>
      )}
    </>
  );
  const desktopContent = (
    <div className={"desktop-content"}>{mobileContent}</div>
  );

  return (
    <div className="home-two" id="home-two">
      <img
        className={"bg-img"}
        src={deviceState.isDesktop ? desktopPhoto : mobilePhoto}
        alt={""}
      />
      {deviceState.isDesktop ? desktopContent : mobileContent}
    </div>
  );
};

export default PartTwo;
